<template>
  <div>
    <ed-modal v-model="bind.boolExibirCadastroAgendamento" width="100%">
      <div slot="title">
        {{ formData.strNome }}
        {{
          formData.intId
            ? ":: Agendamento #" + formData.intId
            : "Inserir Novo Agendamento"
        }}
      </div>

      <div class="" slot="content">
        <agendamento
          :key="'agendamento-' + $root.$session.versao"
          :formData="formData"
          disabledConsulta
        />
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      strModel="Consulta/Consulta"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      :intTotalFiltrosApliados="bind.intTotalFiltrosApliados"
      :disabledFilters="false"
      @register="onRegister"
      @edit="onEdit"
      @search="getConsulta"
      @filter="getConsulta"
      :config="{ registerName: 'Inserir nova consulta' }"
    >
      <template slot="table-filter">
        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          label="Data da Consulta"
          name="arrayData"
          v-model="formFiltros.arrayDataConsulta"
          multiple
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intCadastroGeralEmpresaId"
          label="Empresa"
          name="intCadastroGeralEmpresaId"
          item-text="strNome"
          route="Cadastro/CadastroGeral"
          :filters="{ intTipoCadastroGeralId: 58 }"
          clearable
        />

        <ed-input-auto-complete
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formFiltros.intFuncionarioId"
          label="Funcionário"
          name="intFuncionarioId"
          item-text="strNome"
          route="Cadastro/Funcionario"
          :filters="{
            intCadastroGeralEmpresaId: formFiltros.intCadastroGeralEmpresaId,
          }"
          clearable
        />

        <span class="ed-form-label text-left">Status</br></span>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-0 pb-0">
           <ed-input-checkbox
              class="mr-2"
              style="float:left;width:auto"
              v-model="formFiltros.boolFinalizado"
              name="boolFinalizado"
              labelItem="Finalizado"
              inline
            />

            <ed-input-checkbox
               class="mr-2"
              style="float:left;width:auto"
              v-model="formFiltros.boolPendente"
              name="boolPendente"
              labelItem="Pendente"
              inline
            />

            <ed-input-checkbox
               class="mr-2"
              style="float:left;width:auto"
              v-model="formFiltros.boolExcluido"
              name="boolExcluido"
              labelItem="Excluído"
              inline
            />
        </div>
       
      </template>
    </ed-table>
  </div>
</template>

<script>
import {
  EdTable,
  EdModal,
  EdInputAutoComplete,
  EdInputCheckbox,
  EdInputDate,
} from "@/components/common/form";
import Agendamento from "@/components/agendamento/partials/view";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {
    EdTable,
    EdModal,
    EdInputAutoComplete,
    EdInputCheckbox,
    EdInputDate,
    Agendamento,
  },
  mounted() {
    this.getConsulta();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  provide() {
    return {
      bind: this.bind,
      salvar: this.salvar,
      getAgendamento: this.getAgendamento,
      onEdit: this.onEdit,
    };
  },
  created() {},
  data() {
    return {
      loading: false,
      bind: {
        boolExibirCadastroAgendamento: false,
        intTotalFiltrosApliados: 1,
      },

      formData: {},
      formFiltros: {
        intFuncionarioId: null,
        intCadastroGeralEmpresaId: null,
        boolFinalizado: false,
        boolExcluido:false,
        boolPendente:true,
        arrayDataConsulta: [
          this.$utilidade.addDate(-1, null, "M", "DD/MM/YYYY"),
          this.$utilidade.toDate(null, true, "DD/MM/YYYY"),
        ],
      },
      arrayHeaders: [
        {
          text: "Código",
          sortable: true,
          value: "intId",
        },
        {
          text: "Data",
          sortable: true,
          value: "strDataConsulta",
        },
        {
          text: "Funcionário",
          sortable: true,
          collapse: 30,
          showFuncao: true,
          value: "strNomeFuncionario",
        },
        {
          text: "Tipo de Consulta",
          sortable: true,
          value: "strTipoConsulta",
        },
        {
          text: "Empresa",
          sortable: true,
          showUnidade: true,
          collapse: 30,
          value: "strNomeEmpresa",
        },
        {
          text: "Méd Examinador",
          sortable: true,
          value: "strNomeMedicoExaminador",
        },
      ],
      arrayRows: [],
      objPagination: null,
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "consulta.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "consulta.edit",
        params: { intId: data.intId },
      });
    },

    abrirAgendamento(data) {
      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Agendamento/Agendamento", { intId: data.intAgendamentoId })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {

            objResponse.result.boolFuncionarioNaoIdentificado = true;

            if (!objResponse.result.intFuncionarioId) {
              objResponse.result.boolFuncionarioNaoIdentificado = false;
            }
            this.bind.boolExibirCadastroAgendamento = true;
            this.bind.boolEditandoAgendamento = true;
            this.bind.arrayHorarioAgendamento = [
              {
                intId: objResponse.result.strHoraAgendamentoInicial,
                strNome: objResponse.result.strHoraAgendamentoInicial,
              },
            ];

            this.formData = _.cloneDeep(objResponse.result);

            let self = this;
            setTimeout(() => {
              self.bind.boolEditandoAgendamento = false;
            }, 800);
          }
        });
    },

    getConsulta() {
      this.loading = true;
      this.arrayRows = [];

      if (this.$route.query.intConsultaId && !this.formFiltros.strBusca) {
        this.formFiltros.strBusca = this.$route.query.intConsultaId
      }

      this.$root.$request
        .get("Consulta/Consulta", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objConsulta) => {
              let strTipoExame = objConsulta.sub_grupo.strNome;
              let strTipoConsulta = objConsulta.grupo.strNome;
              let strNomeEmpresa = objConsulta.empresa
                ? objConsulta.empresa.strNome
                : "---";
              let strNomeFuncionario = objConsulta.funcionario.strNome;
              let strNomeMedicoExaminador = objConsulta.medico_examinador
                ? objConsulta.medico_examinador.strNome
                : "---";

              if (objConsulta.intMeses) {
                strTipoExame += " " + objConsulta.intMeses + " meses";
              }

              let item = {
                intId: objConsulta.intId,
                strNomeEmpresa: strNomeEmpresa,
                strNomeFuncionario: strNomeFuncionario,
                strTipoConsulta:
                  strTipoConsulta +
                  '<span style="display:block;font-size:9pt;color:#999999">' +
                  strTipoExame +
                  "</span>",
                strNomeMedicoExaminador: strNomeMedicoExaminador,
                strDataConsulta: this.$utilidade.toDate(
                  objConsulta.strDataConsulta,
                  true
                ),
                buttons: [],
                _item: objConsulta,
              };

              if (objConsulta.strDataExclusao) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("excluido"),
                  color: "error",
                  title: "Consulta foi excluída",
                });
              }
              else if (!objConsulta.boolFinalizado) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("pendente"),
                  color: "warning",
                  title: "Consulta está aguardando finalização",
                });
              }
              else if (objConsulta.boolFinalizado) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("finalizado"),
                  color: "success",
                  title: "Consulta está finalizado",
                });
              }

              if (objConsulta.intAgendamentoId) {
                item.buttons.push({
                  icone: this.$utilidade.getIcone("calendario"),
                  color: "warning",
                  title: "Consulta gerado pelo agendamento",
                  click: this.abrirAgendamento,
                });
              }

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
